import React, { useRef } from "react"
import { Link, graphql } from "gatsby"
import { Layout } from "../../components/Layout/layout"
import styled from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar } from "swiper";
import SwiperArrow from "../../components/SwiperArrow/SwiperArrow";
import Section from "../../components/Section/Section";
import PlpBanner from "../../components/PlpBanner/PlpBanner";
import PlpProduct from "../../components/PlpProduct/PlpProduct";
import CategoryDivider from "../../components/CategoryDivider/CategoryDivider";
import CategoryDescription from '../../components/CategoryDescription/CategoryDescription';
import { ArrowLinkSmall } from '../../components/ArrowLink/ArrowLink';
import { getMetafields } from "../../utils/getMetafields";
import { Seo } from "../../components/seo"
import { media } from '../../utils/mediaQuery';
import { StoreContext } from "../../context/store-context";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "../../styles/swiper-scrollbar.css";

const CategoryWrapper = styled.section` 
  position: relative;
`

const PlpBannerSection = styled(Section)`

`

const CollectionsWrapper = styled.div` 
  margin: -40px 0 0;

  ${media.large`
    margin: 0;
  `}
`

const CategoryInner = styled.div`
  position: relative;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  display: grid;
  grid-column-gap: 30px;
`

const CategoryDescWrapper = styled.div`
  grid-column: 1 / span 12;
  padding: 0 60px 0 0;

  ${media.large`
    grid-column: 1 / span 3;
  `}
`

const CategoryProductWrapper = styled.div`
  grid-column: 1 / span 12;
  margin: 80px -20px 0;

  ${media.large`
    grid-column: 4 / span 9;
    margin: 50px 0 0;
  `}
`

const CategoryProductInner = styled.div`
  position: relative;
  width: 100%;
`

const LabelWrapper = styled.div`
  grid-column: 1 / span 12;
  margin: 20px 0 0;
  ${media.large`
    display: none;
  `}
`

const OverflowHidden = styled.div`
  overflow: hidden;
`

export default function ProductsIndex({
  data: { collections },
  pageContext: { handle },
}) {

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const collectionsData = collections?.edges.sort((a,b) => {
    const collectionA = a.node;
    const collectionB = b.node;

    const metafieldsA = getMetafields(collectionA);
    const metafieldsB = getMetafields(collectionB);
    
    const indexA = metafieldsA && metafieldsA.order_index ? metafieldsA.order_index.value : 0;
    const indexB = metafieldsB && metafieldsB.order_index ? metafieldsB.order_index.value : 0;
    return indexA - indexB;
  })

  const bannerContent = 'Functional foods made with Nature and backed by science.';
  const { width } = React.useContext(StoreContext);
  const collectionTitles = collectionsData.map((data) => { 
    const collection = data.node;
    const title = collection.title;
    return title;
  })
  const description = `Shop all: ${collectionTitles.toString()}`;

  const getProductLayout = (products, viewport) => {
    return (
      <>
        { products && products.length > 0 ? products.map((product) => {
          const productmeta = getMetafields(product);
          const price = `$${Math.round(product.priceRangeV2.minVariantPrice.amount)}${product.priceRangeV2.minVariantPrice.currencyCode}`;
          const plpImage = productmeta?.plp_image ? productmeta.plp_image.localImage.childImageSharp.gatsbyImageData : null;
          const plpImageSource = plpImage ? plpImage.images.fallback.src : null;
          const plpPlaceholder = plpImage ? plpImage.placeholder.fallback : null;
          const plpImageWidth = plpImage ? plpImage.width : null;
          const plpImageHeight = plpImage ? plpImage.height : null;

          const plpImageHover = productmeta?.plp_hover ? productmeta.plp_hover.localImage.childImageSharp.gatsbyImageData : null;
          const plpImageHoverSource = plpImageHover ? plpImageHover.images.fallback.src : null;
          
          return <SwiperSlide style={viewport === 'mobile' ? {width: '80%'} : null}><Link to={product.slug}><PlpProduct 
          title={product.title} 
          subtitle={productmeta && productmeta.subtitle ? productmeta.subtitle.value : null} 
          description={productmeta && productmeta.product_hints ? `Hints of: ${productmeta.product_hints.value}` : null}
          price={price} 
          image={plpImageSource} 
          productType={product.productType}
          placeholder={{
            src: plpPlaceholder,
            width: plpImageWidth,
            height: plpImageHeight
          }}
          hover={plpImageHoverSource} /></Link></SwiperSlide>
        }) : null }
      </>
    )
  }

  return (
    <Layout plp={'/products'}>
      <Seo title="All Products" description={description} />
      <OverflowHidden>
      <PlpBannerSection none contain>
        <PlpBanner>{bannerContent}</PlpBanner>
      </PlpBannerSection>
      <CollectionsWrapper>
      { collectionsData && collectionsData.length > 0 ? collectionsData.map((data) => {
        const collection = data.node;
        const products = collection.products;
        const title = collection.title;
        const metafields = getMetafields(collection);
        const attributes = metafields && metafields.attributes ? metafields.attributes.value.split(',') : null;
        return (
          <Section>
            <CategoryWrapper>
              <CategoryDivider title={title} attributes={attributes} desktopAttr/>
              <CategoryInner>
                <CategoryDescWrapper>
                  <CategoryDescription label={width >= 1080 ? {to:`/products/${collection.handle}`, text: 'See all'} : null }>{metafields && metafields.show_all_d ? metafields.show_all_d.value : ''}</CategoryDescription>
                </CategoryDescWrapper>
                <CategoryProductWrapper>
                  <CategoryProductInner>
                    { width && width >= 1080 ?
                      <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      onBeforeInit={(swiper) => {
                          swiper.params.navigation.prevEl = navigationPrevRef.current;
                          swiper.params.navigation.nextEl = navigationNextRef.current;
      
      
                          swiper.navigation.destroy()
                          swiper.navigation.init()
                          swiper.navigation.update()
                        
                      }}
                      modules={[Navigation,Scrollbar]}
                      className="mySwiper"
                    >
                        { getProductLayout(products, 'desktop') }
                        <SwiperArrow ref={navigationPrevRef} reverse/>
                        <SwiperArrow ref={navigationNextRef}/>
                      </Swiper>
                    
                    : width && width < 1080 ? <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        scrollbar={true}
                        modules={[Scrollbar]}
                        className="mySwiper"
                        style={{padding:'0 20px 30px'}}
                      >
                          { getProductLayout(products, 'mobile') }
                      </Swiper> : null }
                  
                  </CategoryProductInner>
                </CategoryProductWrapper>
                <LabelWrapper>
                  <Link to={`/products/${collection.handle}`}><ArrowLinkSmall>See all</ArrowLinkSmall></Link>
                </LabelWrapper>
              </CategoryInner>
            </CategoryWrapper>

          </Section> );

      }) : null}
      </CollectionsWrapper>
      </OverflowHidden>
    </Layout>
  )
}

export const query = graphql`
  {
    collections: allShopifyCollection(sort: {order: DESC, fields: handle}) {
      edges {
        node {
          products {
           ...ProductCard
            productType
            metafields {
              key
              value
              localImage {
                childImageSharp {
                  gatsbyImageData(width: 1500, placeholder: BLURRED, quality: 80)
                }
              }
            }
          }
          title
          handle
          description
          descriptionHtml
          metafields {
            value
            key
          }
        }
      }
    }
  }
`

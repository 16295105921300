import React from 'react';
import styled from 'styled-components';
import ArrowIcon from './assets/arrow.svg';
import ChevronIcon from './assets/chevron.svg';

const Wrapper = styled.button` 
  position: absolute;
  top: calc(50% - 32px);
  width: 50px;
  height: 80px;
  background: #383B23;
  z-index: 0;
  outline: none;
  border: none;
  cursor: pointer;
  transition: opacity .2s ease;
  opacity: 1;
  z-index: 1;

  transform: ${props => props.reverse ? 'rotate(180deg)' : 'rotate(0deg)'}; 

  svg {
    display: inline;
  }

  ${({reverse}) => !reverse && `
    right: 0px;
  `}

  ${({reverse}) => reverse && `
    left: 0px;
  `}

  &:hover {
    opacity: 0.2;
  }

  &:disabled {
    opacity: 0;
    visibility: hidden;
  }
`

const SwiperArrow = React.forwardRef((props,ref) => {
  const { reverse } = props;
  return (
    <Wrapper ref={ref} reverse={reverse}>
      <ChevronIcon/>
    </Wrapper>
  )
})

export default SwiperArrow;